import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const Banner = () => {
  return (
    <section className="banner" id="home">
      <Container>
        <Row>
          <Col xs={12} md={5} xl={6}>
            <div>
              <h1> Leverage Solutions </h1>
              <p>
                Empower your business with our expertise. We provide the
                leverage you need to succeed and grow.
              </p>
            </div>
          </Col>
          {/* <Col xs={12} md={7} xl={6}>
            <img src={headerImg} alt="" />
          </Col> */}
        </Row>
      </Container>
    </section>
  );
};

export default Banner;
