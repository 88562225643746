import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Database, EnvelopeFill, TelephoneFill } from "react-bootstrap-icons";
import navIcon1 from "../assets/icons/nav-icon1.svg";
import navIcon2 from "../assets/icons/nav-icon2.svg";
import navIcon3 from "../assets/icons/nav-icon3.svg";
import { Link } from "react-router-dom";

const TopContactInfo = () => {
  return (
    <div className="top_container_info">
      <Container>
        <Row>
          <Col md={6}>
            <Link>
              <TelephoneFill /> +254-720-813-499
            </Link>

            <Link>
              <a href="mailto:techs@leveragesolutions.co.ke">
                <EnvelopeFill /> techs@leveragesolutions.co.ke
              </a>
            </Link>
          </Col>
          <Col md={6} className="social_div">
            <div className="social-icon">
              <a href="/">
                <img src={navIcon1} alt="" />
              </a>
              <a href="/">
                <img src={navIcon2} alt="" />
              </a>
              <a href="/">
                <img src={navIcon3} alt="" />
              </a>
              <a href="http://102.218.215.18:2096">
                <EnvelopeFill color="white" />
              </a>
              <a href="http://102.218.215.18:2083">
                <Database color="white" />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TopContactInfo;
