import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import team from "../../assets/images/team-developer.png";

const WhyChooseUs = () => {
  return (
    <div className="why_choose_us" id="why_choose_us">
      {/* <Helmet>
        <title>Why Choose Us</title>
        <meta name="description" content="Why Choose Leverage Solutions" />
        <link rel="canonical" href="#why_choose_us" />
      </Helmet> */}
      <Container>
        <h3>WHY CHOOSE US</h3>
        <Row>
          <Col md={7}>
            <br />
            <h2>
              What We Promise<span className="v_line"></span>
              <span style={{ color: "#0087ff" }}>
                Reliable Premium IT Solutions
              </span>
            </h2>
            <br />
            <p>
              Choose us for our expertise, innovation, and commitment to
              excellence. We provide tailored IT solutions that drive
              efficiency, enhance security, and support your business growth.
              Our dedicated team ensures seamless integration and exceptional
              support, making us your trusted partner in navigating the digital
              landscape. Let's achieve success together! 🚀
            </p>
            <br />

            <ul>
              <li>
                <b> Expertise:</b> Our team has extensive knowledge and
                experience.
              </li>
              <li>
                <b>Security :</b> We prioritize robust cybersecurity measures.
              </li>
              <li>
                <b>Efficiency :</b> Optimize your resources and focus on core
                activities.
              </li>
              <li>
                <b>Scalability :</b> Solutions tailored to grow with your
                business.
              </li>
              <li>
                <b>Cost-Effective :</b> Affordable services without compromising
                quality.
              </li>
            </ul>
          </Col>
          <Col md={5}>
            <img src={team} alt="" />
          </Col>
        </Row>
        <br />
      </Container>
    </div>
  );
};

export default WhyChooseUs;
