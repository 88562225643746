import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import Cpanel from "./pages/CPanel/Cpanel";
import Mail from "./pages/Mail/Mail";
import PageNotFound from "./components/404PageNotFound";

function App() {
  return (
    <div className="App">
      <Router>
        <NavBar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/cpanel" element={<Cpanel />} />
          <Route exact path="/mail" element={<Mail />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
