import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";

const clients = [
  "Thika Sports Club",
  "Kitale Club",
  "3DEE Apartments",
  "Kericho Club",
  "The Big fish",
  "Jamii Gas",
  "Technology and Material",
];

const ClientCarousel = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 7,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 5,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
    },
  };

  return (
    <div className="clients_carousel" id="our_clients">
      <Container>
        <Row>
          <Col xs={12} md={12} xl={12}>
            <Carousel
              responsive={responsive}
              arrows={false}
              draggable={true}
              showDots={false}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={2500}
              containerClass="carousel-container"
              itemClass="carousel-item-padding-40-px"
            >
              {clients.map((client, index) => {
                return (
                  <div className="client_carousel_item">
                    {/* <img src={karenLogo} alt="" /> */}
                    {client}
                  </div>
                );
              })}
            </Carousel>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ClientCarousel;
