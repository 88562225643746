import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  Cloud,
  Database,
  Key,
  PersonFillCheck,
  Recycle,
  Search,
} from "react-bootstrap-icons";

const Solutions = () => {
  const benefits = [
    {
      icon: <Database size={35} style={{ color: "#00ceff" }} />,
      title: "Dynamic Backup Services",
      desc: "Data is the backbone of every business. Our backup services ensure that your critical information is always protected, no matter what. With real-time backups, seamless recovery options, and robust security measures, we provide peace of mind knowing that your data is safe from unexpected disruptions.",
    },

    {
      icon: <Recycle size={35} style={{ color: "#00ceff" }} />,
      title: "Multiple Restore Options",
      desc: "At Leverage, we understand that data is the lifeblood of your business. That’s why we offer a suite of flexible restore options tailored to meet your unique needs. Whether it’s a critical file, an entire system, or anything in between, we’ve got you covered.",
    },
    {
      icon: <Cloud size={35} style={{ color: "#00ceff" }} />,
      title: "Cloud-hosted services",
      desc: "Unlock the full potential of your business with our cutting-edge cloud-based technologies. Whether you're looking to streamline operations with our robust ERP systems, ensure data integrity with our advanced backup solutions, or enhance performance with top-tier computer hardware, we've got you covered.",
    },

    {
      icon: <Key size={35} style={{ color: "#00ceff" }} />,
      title: "Cybersecurity & Data Recovery Solutions",
      desc: "We understand the critical importance of safeguarding your data against cyber threats and ensuring swift recovery in case of data loss. Our comprehensive services include advanced threat detection, real-time monitoring, and robust backup solutions, all designed to keep your operations running smoothly.",
    },
    {
      icon: <Search size={35} style={{ color: "#00ceff" }} />,
      title: "Offsite Monitoring & Control",
      desc: "Our expert team monitors your infrastructure 24/7, identifying and resolving issues before they impact your business. With real-time alerts and proactive maintenance, you can focus on what you do best while we handle the technical complexities.",
    },
    {
      icon: <PersonFillCheck size={35} style={{ color: "#00ceff" }} />,
      title: "Expert Consultancy",
      desc: "Whether you need guidance on cloud-hosted services, cybersecurity, data recovery solutions, or backup strategies, our team of seasoned professionals is here to help. We understand the unique challenges of your industry and are committed to delivering insights and strategies that align with your goals.",
    },
  ];

  return (
    <div className="solutions" id="solutions">
      {/* <Helmet>
        <title>Solutions</title>
        <meta name="description" content="Top Solutions at Leverage" />
        <link rel="canonical" href="#solutions" />
      </Helmet> */}
      <Container>
        <h3>Benefits You Get With Us</h3>
        <Row>
          {benefits.map((benefit, index) => {
            return (
              <Col md={4} key={index}>
                {benefit.icon}

                <h5>{benefit.title}</h5>

                <p> {benefit.desc}</p>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};

export default Solutions;
