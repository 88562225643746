import React from "react";

const PageNotFound = () => {
  return (
    <div style={{ height: "80vh", padding: "12%" }}>
      <h3>Page Not Found</h3>
    </div>
  );
};

export default PageNotFound;
