import React from "react";
import Banner from "./Banner";
import ClientCarousel from "./ClientCarousel";
import WhatWeDo from "./WhatWeDo";
import WhyChooseUs from "./WhyChooseUs";
import Solutions from "./Solutions";
import { Button, Col } from "react-bootstrap";
import ImageCarousel from "./ImageCarousel";

const Home = () => {
  return (
    <div>
      <Banner />
      <ClientCarousel />
      <WhatWeDo />
      <ImageCarousel />
      <WhyChooseUs />
      <Solutions />
      <div className="contact_us">
        <Col md={6}>
          <h5>
            Leverage Solutions excels with expert IT services, unmatched
            reliability, and innovative solutions. Partner with us today for
            seamless digital transformation!
          </h5>
          <Button>Get A Quote</Button>
        </Col>
      </div>
    </div>
  );
};

export default Home;
