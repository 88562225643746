import React from "react";

const Cpanel = () => {
  // window.location.assign("https://palmsoft.biz:2083/");
  window.location.assign("http://102.218.215.18:2083");

  return (
    <div style={{ height: "80vh", textAlign: "start" }}>
      <h5>Redirecting....</h5>
    </div>
  );
};

export default Cpanel;
