import React, { useEffect, useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import TopContactInfo from "./TopContactInfo";
import logo from "../assets/images/logo.png";

const products = [
  "SBA Memebers CLub MAnagement System",
  "SBA Salon Management System",
  "SBA Hotel Management System",
  "SBA Retail Business",
  "SBA Gym Management System",
  "SBA Guard Management System",
];

const services = [
  "ERP Implementation",
  "Network Installation",
  "Data Security Managment",
  "Computer Hardware Sales",
  "ICT & IOT Consultancy",
];
const NavBar = () => {
  const [activeLink, setActiveLink] = useState("home");
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    //   add event listener on mounting
    window.addEventListener("scroll", onScroll);

    //   remove it when component get removed from the dom
    return () => window.removeEventListener("scroll", onscroll);
  }, []);
  return (
    <>
      <TopContactInfo />
      <Navbar expand="lg" className={scrolled ? "scrolled" : ""}>
        <Container>
          <Navbar.Brand href="/">
            <img src={logo} alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link
                href="#home"
                className={
                  activeLink === "home" ? "active navbar-link" : "navbar-link"
                }
                onClick={() => setActiveLink("home")}
              >
                Home
              </Nav.Link>
              <div className="dropdown custom-dropdown">
                <Nav.Link
                  href="#products"
                  className={
                    activeLink === "products"
                      ? "active navbar-link"
                      : "navbar-link"
                  }
                  onClick={() => setActiveLink("products")}
                >
                  Products
                </Nav.Link>
                <div data-bs-popper="static" className="dropdown-menu">
                  {products.map((prod, index) => {
                    return (
                      <Link
                        key={index}
                        data-rr-ui-dropdown-item
                        className="dropdown-item"
                      >
                        {prod}
                      </Link>
                    );
                  })}
                </div>
              </div>
              <div className="dropdown custom-dropdown">
                <Nav.Link
                  href="#services"
                  className={
                    activeLink === "services"
                      ? "active navbar-link"
                      : "navbar-link"
                  }
                  onClick={() => setActiveLink("services")}
                >
                  Services
                </Nav.Link>
                <div data-bs-popper="static" className="dropdown-menu">
                  {services.map((service, index) => {
                    return (
                      <Link
                        key={index}
                        data-rr-ui-dropdown-item
                        className="dropdown-item"
                      >
                        {service}
                      </Link>
                    );
                  })}
                </div>
              </div>

              <Nav.Link
                href="#aboutus"
                className={
                  activeLink === "aboutus"
                    ? "active navbar-link"
                    : "navbar-link"
                }
                onClick={() => setActiveLink("aboutus")}
              >
                About Us
              </Nav.Link>
            </Nav>
            <span className="navbar-text">
              <button className="vvd">
                <Nav.Link href="#contact">Contact Us</Nav.Link>
              </button>
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavBar;
