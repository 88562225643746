import React from "react";
import { Carousel } from "react-bootstrap";
import image1 from "../../assets/images/services-images/teeing1.jpg";
import image2 from "../../assets/images/services-images/complete-pos-system-hardwares.jpg";
import image3 from "../../assets/images/services-images/190812.jpg";

const c_images = [image1, image2, image3];

const ImageCarousel = () => {
  return (
    <div className="image_carousel">
      <Carousel indicators={false} controls={true}>
        {c_images.map((img, index) => {
          return (
            <Carousel.Item
              key={index}
              style={{ backgroundImage: `url(${img})` }}
            >
              {/* <img src={img} alt="" /> */}
              <Carousel.Caption>
                <p></p>
              </Carousel.Caption>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
};

export default ImageCarousel;
