import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import erp from "../../assets/images/services-images/erp.jpg";
import elec from "../../assets/images/services-images/electronics-comp.jpg";
import wired from "../../assets/images/services-images/rede_wired.jpg";
import security from "../../assets/images/services-images/security.jpg";
import { Search } from "react-bootstrap-icons";

const WhatWeDo = () => {
  const services = [
    {
      title: "ERP Implementation",
      desc: "We implement ERP systems seamlessly, ensuring efficient data migration, comprehensive training, and ongoing support for optimal business performance.",
      image: erp,
    },
    {
      title: "Network Installation",
      desc: "We install network systems seamlessly, ensuring robust connectivity, optimal performance, and secure data transfer. Trust us for reliable solutions.",
      image: wired,
    },
    {
      title: "Data Security Managment",
      desc: "We ensure data security with advanced encryption, regular audits, and robust access controls.",
      image: security,
    },
    {
      title: "Computer Hardware Sales",
      desc: "We offer a comprehensive range of computer hardware, from desktops to servers, tailored to meet diverse business needs and enhance productivity.",
      image: elec,
    },
  ];

  return (
    <div className="what_we_do" id="what_we_do">
      {/* <Helmet>
        <title>What We Do</title>
        <meta name="description" content="What We Do at Leverage Solutions" />
        <link rel="canonical" href="#what_we_do" />
      </Helmet> */}
      <Container>
        <h3>
          What We Do<span className="v_line"></span>
          <span style={{ color: "#0087ff" }}>
            Custom Solutions For Your Business
          </span>
        </h3>
        <br />
        <Row>
          {services.map((item, index) => {
            return (
              <Col key={index} md={3}>
                <div className="what_we_do_img">
                  <img src={item.image} alt="" title={item.title} />
                  <div className="what_we_do_img_content">
                    <Search size={25} color="white" />
                  </div>
                </div>
                <h5>{item.title}</h5>
                <p>{item.desc} </p>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};

export default WhatWeDo;
